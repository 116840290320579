import { Action, createReducer, on } from '@ngrx/store';

import * as LoggerActions from '../actions/logger.actions';
import { User } from 'src/app/commons/models/user.model';
import { LogRecord } from 'src/app/commons/models/user.model';

export interface LoggerState {
    user: User,
    logs: LogRecord[]
};

const initialState: LoggerState = {
    user: null,
    logs: []
};

const loggerReducer = createReducer(
    initialState,
    on(LoggerActions.loadLogsCompleted, (state, { logs, user }) => {
        return { ...state, logs, user };
    })
);

export function reducer(state: LoggerState | undefined, action: Action) {
    return loggerReducer(state, action);
}
