import { Component, Inject, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Role, User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { checkIfMatchingPasswords, passwordValidator } from 'src/app/helpers/validators/password.validator';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: "identifai-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject<void>();
  private _user: User;
  currentUser: boolean;

  roles: Role[];

  userForm: UntypedFormGroup;

  termsOfService = environment.termOfService;
  privacyPolicy = environment.privacyPolicy;

  heuristics: string[] = [];

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { user: User, currentUser?: boolean },
  ) {
    if (this.data) {
      this.user = this.data.user;
      this.currentUser = this.data.currentUser;
    }
    this.store$.pipe(select(AuthSelectors.getHeuristics), takeUntil(this.unsubscribe$)).subscribe(heuristics => { this.heuristics = heuristics; });
  }

  ngOnInit() {
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    if (this.userForm) {
      this.userForm.reset();
      if (this.user) {
        this._initFormValues(this.user);
      } else {
        this._initFormValues();
      }
    }
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      role: ["", Validators.required],
      heuristic: ["", Validators.required],
      address: [""],
      zip: [""],
      city: [""],
      country: [""],
      phone: [""],
      contact: [""],
      vat: [""],
      privacyPolicy: [""],
      tos: [""]
    };

    if (this.user) {
      this.userForm = this.fb.group(group);
    } else {
      // group["password"] = ["", [Validators.required, passwordValidator]];
      // group["confirmPass"] = ["", Validators.required];
      group["password"] = ["", [passwordValidator]];
      group["confirmPass"] = [""];

      this.userForm = this.fb.group(group, {
        validator: checkIfMatchingPasswords("password", "confirmPass"),
      });
    }
  }

  private _initFormValues(user?: User) {
    if (user) {
      this.userForm.patchValue({
        name: user.name,
        email: user.email,
        password: user.password,
        role: user.role,
        address: user.address,
        zip: user.zip,
        city: user.city,
        country: user.country,
        phone: user.phone,
        contact: user.contact,
        vat: user.vat,
        privacyPolicy: user.privacyPolicy,
        tos: user.tos,
        heuristic: user.heuristic
      });
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.ngOnChanges();
  }

  private _prepareSaveUser(): User {
    let savingUser: User = User.fromFormGroup(this.userForm, this.user);
    return savingUser;
  }

  save() {
    let unsavedEntity = this._prepareSaveUser();
    this.store$.dispatch(UserActions.saveUser({ user: unsavedEntity }));
  }

  close() {
    if (this.userForm.pristine) {
      this.store$.dispatch(UserActions.closeUserDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          "Close",
          "There are unsaved changes. Are you sure you want to close?"
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(UserActions.closeUserDialog());
          }
        });
    }
  }

  deleteUser() {
    if (this.currentUser) {
      this.store$.dispatch(UserActions.deleteCurrentUser());
    } else if (this.user) {
      this.store$.dispatch(UserActions.deleteUser({ user: this.user.toDTO() }));
    }
  }

  changePassword() {
    if (this.user) {
      this.store$.dispatch(
        UserActions.changeUserPassword({ user: this.user.toDTO() })
      );
    }
  }

  apiKey() {
    if (this.user) {
      this.store$.dispatch(UserActions.openApiKeyDialog({ user: this.user }));
    }
  }

  sendCreationEmail() {
    if (this.user) {
      this.store$.dispatch(UserActions.sendCreationEmail({ userId: this.user.id }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
