import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromRouter from '@ngrx/router-store';
import * as fromSidebar from './sidebar.reducer';
import * as fromAuth from './auth.reducers';
import * as fromUser from './user.reducer';
import * as fromCredit from './credit.reducer';
import * as fromRequest from './request.reducer';
import * as fromReport from './report.reducer';
import * as fromImage from './image.reducer';
import * as fromLogger from './logger.reducer';
import * as fromPricing from './pricing.reducer';
import * as fromConfig from './config.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState<any>;
  auth: fromAuth.AuthState;
  user: fromUser.UserState;
  sidebar: fromSidebar.SidebarState;
  credit: fromCredit.CreditState;
  request: fromRequest.RequestState;
  report: fromReport.ReportState;
  image: fromImage.ImageState;
  logs: fromLogger.LoggerState;
  pricing: fromPricing.PricingState;
  config: fromConfig.ConfigState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  user: fromUser.reducer,
  sidebar: fromSidebar.reducer,
  credit: fromCredit.reducer,
  request: fromRequest.reducer,
  report: fromReport.reducer,
  image: fromImage.reducer,
  logs: fromLogger.reducer,
  pricing: fromPricing.reducer,
  config: fromConfig.reducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [{ auth: ["token"] }],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<
  fromRouter.RouterReducerState<any>
>("router");
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  "auth"
);
export const selectUserState = createFeatureSelector<fromUser.UserState>(
  "user"
);
export const selectSidebarState = createFeatureSelector<fromSidebar.SidebarState>(
  "sidebar"
)
export const selectCreditState = createFeatureSelector<fromCredit.CreditState>(
  "credit"
)
export const selectRequestState = createFeatureSelector<fromRequest.RequestState>(
  "request"
)
export const selectReportState = createFeatureSelector<fromReport.ReportState>(
  "report"
);
export const selectImageState = createFeatureSelector<fromImage.ImageState>(
  "image"
)
export const selectLoggersState = createFeatureSelector<fromLogger.LoggerState>(
  "logs"
)
export const selectPricingState = createFeatureSelector<fromPricing.PricingState>(
  "pricing"
)
export const selectConfigState = createFeatureSelector<fromConfig.ConfigState>(
  "config"
)

const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = fromRouter.getRouterSelectors(selectRouter);

export const selectRouteId = selectRouteParam("id");
export const selectStatus = selectQueryParam("status");
