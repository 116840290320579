import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogRecord, User } from 'src/app/commons/models/user.model';

@Injectable({
    providedIn: "root"
})
export class LaravelLoggerService {

    constructor(private httpClient: HttpClient) { }

    private get ROUTES() {
        return {
            logs: environment.baseUrl + "/api/logs"
        };
    }

    public logs(user_id: number): Observable<LogRecord[]> {
        //let params = {};
        //params["id"] = user_id;
        //console.log("[Laravel-service] " + user_id);

        return this.httpClient.get<LogRecord[]>(`${this.ROUTES.logs}/${user_id}`);//, {
        //     params: new HttpParams({ fromObject: params })
        // });
    }
}
