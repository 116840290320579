import { sendCreationEmail } from './../actions/user.actions';
import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Actions, createEffect, ofType, concatLatestFrom } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { combineLatest, of } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { User, UserDTO } from "src/app/commons/models/user.model";

import * as AuthSelectors from "src/app/store/selectors/auth.selectors";
import * as UserSelectors from "src/app/store/selectors/user.selectors";

import { AlertService } from "../../commons/services/alert.service";
import { LaravelUserService } from "../../commons/services/backend/laravel-user.service";

import * as AuthActions from "../actions/auth.actions";
import * as UserActions from "../actions/user.actions";
import * as CreditActions from "../actions/credit.actions";
import * as RequestActions from "../actions/request.actions";
import * as ReportActions from "../actions/report.actions";

import { AppState } from "../reducers";
import {
  getUserDialogId,
  getUsersTableState,
} from "src/app/store/selectors/user.selectors";
import { UserEditComponent } from "src/app/modules/shared/components/user/user-edit/user-edit.component";
import { PasswordChangeComponent } from "src/app/modules/shared/components/user/password-change/password-change.component";
import { UserSelectionComponent } from "src/app/modules/shared/components/user/user-selection/user-selection.component";
import { ApiKeyComponent } from "src/app/modules/shared/components/user/api-key/api-key.component";
import { TermsOfServiceComponent } from "src/app/modules/shared/components/user/tos/tos.component";

@Injectable()
export class UserEffects {
  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.saveUserFailed, UserActions.loadUsersFailed, UserActions.loadApiKeysFailed, UserActions.loadUsersGraphFailed, UserActions.sendCreationEmailFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage("Error", error);
          }
        })
      );
    },
    { dispatch: false }
  );

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.userService
          .list(page, perPage, order, direction, filters, includes)
          .pipe(
            map((result) =>
              UserActions.loadUsersCompleted({
                users: result.data,
                currentPage: page,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(UserActions.loadUsersFailed({ error }));
            })
          );
      })
    );
  });

  changePage = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changePage),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([
          { page, size },
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          UserActions.loadUsers({
            page: page,
            perPage: size,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  changeSort = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeSort),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([
          action,
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage: perPage,
            order: action.order,
            direction: action.direction,
            filters,
            includes,
          })
      )
    );
  });

  changeFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeFilters),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([
          { filters },
          { total, currentPage, perPage, direction, order, includes },
        ]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage: perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  editUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.editUser),
      map(({ user }) => {
        let dialogRef = this.dialog.open(UserEditComponent, {
          data: {
            user,
          },
        });
        return UserActions.userDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  saveUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUser),
      mergeMap(({ user }) =>
        this.userService.upsert(user.toDTO()).pipe(
          map((result) => UserActions.saveUserCompleted({ user: result })),
          catchError((error) => of(UserActions.saveUserFailed({ error })))
        )
      )
    );
  });

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUserCompleted),
      map((action) => action.user),
      tap((user) =>
        this.alertService.showConfirmMessage(
          `User saved successfully`,
        )
      ),
      map(() => UserActions.closeUserDialog())
    );
  });

  updateCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUserCompleted, UserActions.resetApiKeyCompleted),
      concatLatestFrom(() => this.store$.select(AuthSelectors.getCurrentUser)),
      map(([{ user }, currentUser]) => [user, currentUser]),
      filter(([user, currentUser]) => user.id === currentUser.id),
      map(([user, _]) => AuthActions.loadCurrentUserCompleted({ currentUser: user }))
    );
  })

  changeUserPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeUserPassword),
      map(({ user }) => {
        let dialogRef = this.dialog.open(PasswordChangeComponent, {
          data: {
            user,
          },
        });
        return UserActions.changePasswordDialogOpen({ dialogId: dialogRef.id });
      })
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updatePassword),
      flatMap(({ newPassword, user }) => {
        if (user) {
          return of({ newPassword, user });
        } else {
          return this.store$.select(AuthSelectors.getCurrentUser).pipe(
            take(1),
            map((user) => {
              return { newPassword, user };
            })
          );
        }
      }),
      switchMap(({ newPassword, user }) => {
        let newUser: UserDTO = JSON.parse(JSON.stringify(user));
        newUser.password = newPassword;
        return this.userService.upsert(newUser).pipe(
          map((user) => UserActions.updatePasswordCompleted({ user })),
          catchError((error) => {
            return of(UserActions.updatePasswordFailed({ error }));
          })
        );
      })
    );
  });

  onUpdatePasswordCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updatePasswordCompleted),
      map((action) => action.user),
      tap((user) =>
        this.alertService.showConfirmMessage(
          `Password updated successfully`
        )
      ),
      map(() => UserActions.closeChangePasswordDialog())
    );
  });

  onUpdatePasswordFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updatePasswordFailed),
      tap((err) =>
        this.alertService.showErrorMessage(
          'Error',
          err
        )
      ),
      map(() => UserActions.closeChangePasswordDialog())
    );
  });

  closeChangePasswordDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.closeChangePasswordDialog),
        concatLatestFrom(() =>
          this.store$.select(UserSelectors.getChangePasswordDialogId)
        ),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUser),
      switchMap(({ user }) =>
        this.alertService
          .showConfirmDialog(
            "Confirm deletion",
            `Are you sure you want to delete the user ${user.name}?`
          )
          .pipe(
            mergeMap((confirm) => {
              return confirm
                ? this.userService.delete(user.id).pipe(
                  map(() => UserActions.deleteUserCompleted({ user })),
                  catchError((error) =>
                    of(UserActions.deleteUserFailed({ error }))
                  )
                )
                : of(UserActions.deleteUserCancelled());
            })
          )
      )
    );
  });

  onDeleteCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      tap(({ user }) =>
        this.alertService.showConfirmMessage(
          `User ${user.name} deleted successfully`
        )
      ),
      map(() => UserActions.closeUserDialog())
    );
  });

  onDeleteUserFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUserFailed),
      tap((err) =>
        this.alertService.showErrorMessage(
          'Error',
          err
        )
      ),
      map(() => UserActions.closeUserDialog())
    );
  })

  deleteCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteCurrentUser),
      withLatestFrom(this.store$.select(AuthSelectors.getCurrentUser)),
      switchMap(([_, user]) =>
        this.alertService
          .showConfirmDialog(
            "Confirm deletion",
            `Are you sure you want to delete your account? This action cannot be undone.`
          )
          .pipe(
            mergeMap((confirm) => {
              return confirm
                ? this.userService.delete(user.id).pipe(
                  map(() => UserActions.deleteCurrentUserCompleted({ user })),
                  catchError((error) =>
                    of(UserActions.deleteCurrentUserFailed({ error }))
                  )
                )
                : of(UserActions.deleteCurrentUserCancelled());
            })
          )
      )
    );
  });

  onDeleteCurrentUserCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteCurrentUserCompleted),
      tap(({ user }) =>
        this.alertService.showConfirmMessage(
          `User deleted successfully`
        )
      ),
      map(() => AuthActions.logout({ showConfirm: false }))
    );
  });

  onDeleteCurrentUserFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteCurrentUserFailed),
      tap((err) =>
        this.alertService.showErrorMessage(
          'Error',
          err
        )
      ),
      map(() => UserActions.closeUserDialog())
    );
  })


  closeDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.closeUserDialog),
        concatLatestFrom(() => this.store$.select(getUserDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUserCompleted),
      concatLatestFrom(() => this.store$.select(AuthSelectors.isAdmin)),
      filter(([_, isAdmin]) => isAdmin),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  reloadAfterDelete = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      concatLatestFrom(() => this.store$.select(AuthSelectors.isAdmin)),
      filter(([_, isAdmin]) => isAdmin),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  showBalance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreditActions.showBalance),
      tap(() => {
        this.router.navigate([`/credits`]);
      })
    );
  }, { dispatch: false });

  showRequests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.showRequests),
      tap(() => {
        this.router.navigate([`/requests`]);
      })
    );
  }, { dispatch: false });

  showReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.showReports),
      tap(() => {
        this.router.navigate([`/reports`]);
      })
    );
  }, { dispatch: false });

  selectUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.selectUser),
      map(({ filters }) => {
        let dialogRef = this.dialog.open(UserSelectionComponent, {
          data: {
            defaultFilters: filters
          }
        });
        return UserActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
      }))
  }
  );
  closeSelectionDialog = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.closeSelectionDialog),
      concatLatestFrom(() => this.store$.select(UserSelectors.getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    )
  }, { dispatch: false }
  );

  usersSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userSelected),
      map(() => UserActions.closeSelectionDialog())
    )
  })

  editCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.editCurrentUser),
      concatLatestFrom(() => this.store$.select(AuthSelectors.getCurrentUser)),
      map(([_, user]) => user ? new User(user) : null),
      map(user => {
        let dialogRef = this.dialog.open(UserEditComponent, {
          data: {
            user,
            currentUser: true
          },
        });
        return UserActions.userDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  openApiKeyDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.openApiKeyDialog),
      map(({ user }) => {
        let dialogRef = this.dialog.open(ApiKeyComponent, {
          data: {
            user,
          },
          minWidth: '400px',
        });
        return UserActions.apiKeyDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  closeApiKeyDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.closeApiKeyDialog),
        concatLatestFrom(() =>
          this.store$.select(UserSelectors.getApiKeyDialogId)
        ),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );


  resetApiKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetApiKey),
      mergeMap(({ userId }) => {
        if (userId) {
          return of({ userId });
        } else {
          return this.store$.select(AuthSelectors.getCurrentUser).pipe(
            take(1),
            map((user) => {
              return { userId: user.id };
            })
          );
        }
      }),
      switchMap(({ userId }) => {
        return this.userService.resetApiKey(userId).pipe(
          map((user) => UserActions.resetApiKeyCompleted({ user })),
          catchError((error) => {
            return of(UserActions.resetApiKeyFailed({ error }));
          })
        );
      })
    );
  });

  onResetApiKeyCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetApiKeyCompleted),
      map((action) => action.user),
      tap((user) =>
        this.alertService.showConfirmMessage(
          `ApiKey updated successfully`
        )
      ),
      map(() => UserActions.closeApiKeyDialog())
    );
  });

  onResetApiKeyFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetApiKeyFailed),
      tap((err) =>
        this.alertService.showErrorMessage(
          'Error',
          err
        )
      ),
      map(() => UserActions.closeApiKeyDialog())
    );
  });

  loadApiKeys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadApiKeys),
      switchMap(({ userId }) => {
        return this.userService
          .loadApiKeys(userId)
          .pipe(
            map((result) =>
              UserActions.loadApiKeysCompleted({
                apiKeys: result,
              })
            ),
            catchError((error) => {
              return of(UserActions.loadApiKeysFailed({ error }));
            })
          );
      })
    );
  });

  checkTOS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserCompleted, AuthActions.loginCompleted),
      switchMap(({ currentUser }) => {
        if (!currentUser.tos) return of(UserActions.openTOSDialog());
        return of(UserActions.tosOk());
      })
    );
  });

  openTOSDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.openTOSDialog),
      map(() => {
        let dialogRef = this.dialog.open(TermsOfServiceComponent, {
          minWidth: '400px',
        });
        return UserActions.tosDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  acceptTOS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.acceptTos),
      concatLatestFrom(() => this.store$.select(AuthSelectors.getCurrentUser)),
      map(([_, user]) => new User(user)),
      mergeMap((user: User) => {
        let newUser = user.toDTO();
        newUser.tos = true;

        return this.userService.upsert(newUser).pipe(
          tap(() => this.alertService.showConfirmMessage('TOS accepted')),
          tap(() => window.location.reload()),
          map(() => UserActions.closeTOSDialog()),
          catchError((error) => of(UserActions.saveUserFailed({ error })))
        )
      })
    );
  });

  rejectTOS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.rejectTos),
      tap(() => this.alertService.showConfirmMessage('TOS rejected')),
      map(() => AuthActions.logout({ showConfirm: false }))
    )
  });

  closeTOSDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.closeTOSDialog, UserActions.rejectTos),
      concatLatestFrom(() =>
        this.store$.select(UserSelectors.getTosDialogId)
      ),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    );
  }, { dispatch: false });

  loadUsersGraph$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsersGraph),
      switchMap(() => {
        return this.userService.graph().pipe(
          map((result) =>
            UserActions.loadUsersGraphCompleted({ result })
          ),
          catchError((error) => {
            return of(UserActions.loadUsersGraphFailed({ error }));
          })
        );
      })
    );
  });

  sendCreationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendCreationEmail),
      switchMap(({ userId }) =>
        this.alertService.showConfirmDialog(
          'Send creation email',
          'Are you sure you want to send a creation email to the user?',
        ).pipe(
          filter(confirm => confirm),
          switchMap(() =>
            this.userService.sendCreationEmail(userId).pipe(
              map((user) => UserActions.sendCreationEmailCompleted({ user })),
              catchError((error) => of(UserActions.sendCreationEmailFailed({ error })))
            )
          ))
      )
    )
  )

  sendCreationEmailCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendCreationEmailCompleted),
      tap(({ user }) => {
        this.alertService.showConfirmMessage(`Creation email sent successfully to ${user.email}`);
      })
    ), { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userService: LaravelUserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private router: Router
  ) { }
}
