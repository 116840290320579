import { Action, createReducer, on } from '@ngrx/store';

import * as ConfigActions from '../actions/config.actions';
import { CreditFilters, Credit, CreditDTO, CreditGraphData, CreditUsage } from 'src/app/commons/models/credit.model';
import { GraphMultiSeries } from 'src/app/helpers/graph.utils';

export interface ConfigState {
  models: string[]
};

const initialState: ConfigState = {
  models: [],
};

const configReducer = createReducer(
  initialState,
  on(ConfigActions.loadAvailableModeslCompleted, (state, { models }): ConfigState => {
    return { ...state, models };
  })
);

export function reducer(state: ConfigState | undefined, action: Action) {
  return configReducer(state, action);
}

