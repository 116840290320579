import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Pricing } from 'src/app/commons/models/pricing.model';
import { BIG_PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { PricingFilters } from 'src/app/commons/models/pricing.model';

export type PricingsColumn = "id" | "name" | "sku" | "actions";

@Component({
  selector: 'pricing-list',
  templateUrl: './pricing-list.component.html',
  styleUrls: ['./pricing-list.component.scss']
})
export class PricingListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  pricings: Observable<Pricing[]>;

  @Input()
  defaultFilters: PricingFilters;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: PricingsColumn[] = ["name", "sku", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<PricingFilters> = new EventEmitter<PricingFilters>();

  @Output()
  onSelectPricing: EventEmitter<Pricing> = new EventEmitter<Pricing>();
  @Output()
  onAddPricing: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditPricing: EventEmitter<Pricing> = new EventEmitter<Pricing>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addPricing() {
    this.onAddPricing.emit();
  }

  editPricing(pricing: Pricing) {
    this.onEditPricing.emit(pricing);
  }

  selectPricing(pricing: Pricing) {
    this.onSelectPricing.emit(pricing);
  }

  onFilter(filters: PricingFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
