<!-- <identifai-pricing-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</identifai-pricing-filters> -->
<mat-card class="u-center-text" *ngIf="!(pricings | async).length">
  <h4>No pricing found</h4>
  <!-- <button *ngIf="canAdd" mat-raised-button (click)="addPricing()">Add</button> -->
</mat-card>
<div [hidden]="!(pricings | async).length" class="table-container">
  <table mat-table matSort [dataSource]="pricings">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let pricing">{{ pricing.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let pricing">
        {{ pricing.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef>Sku</th>
      <td mat-cell *matCellDef="let pricing">
        {{ pricing.sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let pricing">
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectPricing(pricing)">
          Select
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editPricing(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
