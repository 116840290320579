import { createAction, props } from '@ngrx/store';

import { Pricing, PricingDTO, PricingFilters } from 'src/app/commons/models/pricing.model';

export const loadPricings = createAction('[Pricings] Load pricings', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: PricingFilters, includes?: string[] }>());
export const loadPricingsCompleted = createAction('[Pricings] Load pricings Completed', props<{ pricings: PricingDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: PricingFilters, includes?: string[] }>());
export const loadPricingsFailed = createAction('[Pricings] Load pricings Failed', props<{ error: any }>());

export const changePage = createAction('[Pricings] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Pricings] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Pricings] Change filters', props<{ filters: PricingFilters }>());

export const editPricing = createAction('[Pricing] Edit pricing', props<{ pricing?: Pricing }>());
export const pricingDialogOpened = createAction('[Pricing] Detail dialog opened', props<{ dialogId: string }>());
export const closePricingDialog = createAction('[Pricing] Close detail dialog');

export const savePricing = createAction('[Pricing] Save pricing', props<{ pricing: Pricing }>());
export const savePricingCompleted = createAction('[Pricing] Save pricing completed', props<{ pricing: PricingDTO }>());
export const savePricingFailed = createAction('[Pricing] Save pricing failed', props<{ error: any }>());

export const deletePricing = createAction('[Pricing] Delete pricing', props<{ pricing: PricingDTO }>());
export const deletePricingCompleted = createAction('[Pricing] Delete pricing completed', props<{ pricing: PricingDTO }>());
export const deletePricingFailed = createAction('[Pricing] Delete pricing failed', props<{ error: any }>());

export const selectPricing = createAction('[Pricings] Select pricing', props<{ filters?: PricingFilters, currentFormControlName: string }>());
export const selectionDialogOpened = createAction('[Pricings] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Pricings] Close selection dialog');
export const pricingSelected = createAction('[Pricings] Pricing selected', props<{ pricing: PricingDTO }>());
