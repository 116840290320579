import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date): unknown {
    return value ? value.toLocaleString('it-IT', { timeZone: 'Europe/Rome' }) : '';
  }

}
