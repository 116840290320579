import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { AlertService } from "../../commons/services/alert.service";
import * as LoggerActions from "../actions/logger.actions";
import * as AuthSelectors from "../selectors/auth.selectors";
import { AppState } from "../reducers";
import { Router } from "@angular/router";
import { LaravelLoggerService } from "src/app/commons/services/backend/laravel-logger.service";
import { User } from "src/app/commons/models/user.model";

@Injectable()
export class LoggerEffects {

  showUserLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoggerActions.showUserLogs),
      map((user) => {
        return LoggerActions.loadLogs(user);
      })
    );
  });

  showCurrentUserLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoggerActions.showCurrentUserLogs),
      withLatestFrom(this.store$.select(AuthSelectors.getCurrentUser)),
      filter(([_, currentUser]) => !!currentUser),
      map(([_, currentUser]) => {
        return LoggerActions.loadLogs({ user: new User(currentUser) });
      })
    );
  });

  navigateToLogsPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoggerActions.showUserLogs, LoggerActions.showCurrentUserLogs),
      tap(() => {
        this.router.navigate([`/logs`]);
      })
    );
  }, { dispatch: false });

  loadLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoggerActions.loadLogs),
      switchMap(({ user }) => {
        return this.loggerService
          .logs(user.id)
          .pipe(
            map((result) =>
              LoggerActions.loadLogsCompleted({
                logs: result,
                user
              })
            ),
            catchError((error) => {
              return of(LoggerActions.loadLogsError({
                error: error
              })
              )
            })
          )
      })
    )
  });

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private loggerService: LaravelLoggerService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private router: Router
  ) { }
}
