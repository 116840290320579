import { createAction, props } from '@ngrx/store';
import {
  Request,
  RequestDTO,
  RequestFilters,
  RequestGraphFilters,
  RequestsGraphData,
  RequestType,
} from 'src/app/commons/models/request.model';
import { User } from 'src/app/commons/models/user.model';
import { GraphMultiSeries } from 'src/app/helpers/graph.utils';


export const loadRequests = createAction('[Requests] Load requests', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: RequestFilters, includes?: string[] }>());
export const loadRequestsCompleted = createAction('[Requests] Load requests Completed', props<{ requests: RequestDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RequestFilters, includes?: string[] }>());
export const loadRequestsFailed = createAction('[Requests] Load requests Failed', props<{ error: any }>());

export const loadDashboardGraphs = createAction('[Requests] Load dashboard graphs');

export const loadRequestsGraph = createAction('[Requests] Load requests graph');
export const loadRequestsGraphCompleted = createAction('[Requests] Load requests graph Completed', props<{ result: RequestsGraphData }>());
export const loadRequestsGraphFailed = createAction('[Requests] Load requests graph Failed', props<{ error: any }>());

export const loadGraphUserAndType = createAction('[Requests] Load graph by user and type');
export const loadGraphUserAndTypeCompleted = createAction('[Requests] Load graph by user and type Completed', props<{ result: GraphMultiSeries[] }>());
export const loadGraphUserAndTypeFailed = createAction('[Requests] Load graph by user and type Failed', props<{ error: any }>());

export const loadGraphUserAndVerdict = createAction('[Requests] Load graph by user and verdict');
export const loadGraphUserAndVerdictCompleted = createAction('[Requests] Load graph by user and verdict Completed', props<{ result: GraphMultiSeries[] }>());
export const loadGraphUserAndVerdictFailed = createAction('[Requests] Load graph by user and verdict Failed', props<{ error: any }>());

export const loadGraphDateAndVerdict = createAction('[Requests] Load graph by date and type');
export const loadGraphDateAndVerdictCompleted = createAction('[Requests] Load graph by date and type Completed', props<{ result: GraphMultiSeries[] }>());
export const loadGraphDateAndVerdictFailed = createAction('[Requests] Load graph by date and type Failed', props<{ error: any }>());

export const loadGraphImagesDateAndVerdict = createAction('[Requests] Load graph images by date and type');
export const loadGraphImagesDateAndVerdictCompleted = createAction('[Requests] Load graph images  by date and type Completed', props<{ result: GraphMultiSeries[] }>());
export const loadGraphImagesDateAndVerdictFailed = createAction('[Requests] Load graph images by date and type Failed', props<{ error: any }>());

export const loadGraphVideosDateAndVerdict = createAction('[Requests] Load graph videos by date and type');
export const loadGraphVideosDateAndVerdictCompleted = createAction('[Requests] Load graph videos by date and type Completed', props<{ result: GraphMultiSeries[] }>());
export const loadGraphVideosDateAndVerdictFailed = createAction('[Requests] Load graph videos by date and type Failed', props<{ error: any }>());

export const changeGraphFilters = createAction('[Requests] Change graph filters', props<{ filters: RequestGraphFilters }>());

export const refreshRequest = createAction('[Requests] Refresh request', props<{ request: RequestDTO }>());
export const refreshRequestCompleted = createAction('[Requests] Refresh request Completed', props<{ request: RequestDTO }>());
export const refreshRequestFailed = createAction('[Requests] Refresh request Failed', props<{ error: any }>());

export const reprocess = createAction('[Requests] Reprocess request', props<{ request: RequestDTO }>());
export const reprocessCompleted = createAction('[Requests] Reprocess request Completed', props<{ request: RequestDTO }>());
export const reprocessFailed = createAction('[Requests] Reprocess request Failed', props<{ error: any }>());

export const changePage = createAction('[Requests] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Requests] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Requests] Change filters', props<{ filters: RequestFilters }>());

export const openRequestDialog = createAction('[Requests] Opened request dialog');
export const requestDialogOpened = createAction('[Requests] Detail dialog opened', props<{ dialogId: string }>());
export const closeRequestDialog = createAction('[Requests] Close detail dialog');

export const showRequestDialog = createAction('[Requests] Opened show request dialog', props<{ request: Request }>());

export const sendRequest = createAction('[Request] Save request', props<{ file: File, requestType: RequestType, frames?: number }>());
export const sendRequestCompleted = createAction('[Request] Save request completed', props<{ request: RequestDTO }>());
export const sendRequestFailed = createAction('[Request] Save request failed', props<{ error: any }>());

export const showRequests = createAction('[Request] Show requests', props<{ user?: User }>());

export const exportRequests = createAction('[Requests] Export Requests', props<{ userId?: number }>());
export const exportRequestsCompleted = createAction('[Requests] Export Requests Completed', props<{ data: Blob }>());
export const exportRequestsFailed = createAction('[Requests] Export Requests Failed', props<{ error: any }>());

export const deleteRequest = createAction('[Requests] Delete request', props<{ request: RequestDTO }>());
export const deleteRequestCompleted = createAction('[Requests] Delete request completed', props<{ request: RequestDTO }>());
export const deleteRequestFailed = createAction('[Requests] Delete request failed', props<{ error: any }>());

export const saveJsonToFile = createAction('[Requests] Save JSON to file', props<{ data: any, filename: string }>());
