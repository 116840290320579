import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import { AppState } from 'src/app/store/reducers';
import { getCurrentUser } from 'src/app/store/selectors/auth.selectors';

@Directive({ selector: "[can]" })
export class CanDirective implements OnInit {
  private destroy$ = new Subject<any>();
  private _permission: string;
  private viewRef: EmbeddedViewRef<any>;

  constructor(private templateRef: TemplateRef<any>, private store$: Store<AppState>, private viewContainer: ViewContainerRef) { }

  get permission(): string {
    return this._permission;
  }

  @Input()
  set can(permission: string) {
    this._permission = permission;
  }
  ngOnInit() {
    this.store$
      .pipe(
        select(getCurrentUser),
        distinctUntilChanged(),
        map(dto => new User(dto)),
        map(user => user.hasPermission(this.permission)),
        takeUntil(this.destroy$)
      )
      .subscribe(show => {
        if (show) {
          // remove templateRef if already created
          if (this.viewRef) {
            this.viewContainer.remove(this.viewContainer.indexOf(this.viewRef));
          }
          this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
          this.viewRef = null;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
