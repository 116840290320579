import { Action, createReducer, on } from '@ngrx/store';
import * as SidebarActions from '../actions/sidebar.actions';

export interface SidebarState {
  toggled: boolean;
};

const initialState: SidebarState = {
    toggled: false,
};

const clientReducer = createReducer(
  initialState,
  on(SidebarActions.sidebarToggle, (state) => {
    return { toggled: !state.toggled };
  }),
);

export function reducer(state: SidebarState | undefined, action: Action) {
  return clientReducer(state, action);
}