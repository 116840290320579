import { UntypedFormGroup } from '@angular/forms';

import { Base, BaseDTO } from './base.model';

export interface PricingFilters {
  search?: string;
}

export type Period = "month" | "year" | "none";
export const PERIODS: Period[] = ["month", "year", "none"];
export interface PricingDetails {
  price: number,
  credits: number,
  discount: 0, //percentage
  period: Period,
  request_options: {
    models: string[],
    morphing: boolean,
    llm: boolean,
    video: boolean,
    video_keyframes: boolean,
    video_credit_choice: boolean
  },
  response_options: {
    model_details: boolean,
    heuristics: boolean,
    telemetry: boolean,
    bias_detection: boolean
  }
}

export interface PricingDTO extends BaseDTO {
  name: string;
  sku: string;
  details: PricingDetails;
}

export class Pricing extends Base {
  name: string;
  sku: string;
  details: PricingDetails;

  constructor(source: PricingDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.sku = source.sku;
      this.details = source.details;
    }
  }

  toDTO(): PricingDTO {
    let result: PricingDTO = <PricingDTO>super.toDTO();
    result.name = this.name;
    result.sku = this.sku;
    result.details = this.details;

    return result;
  }

  static fromFormGroup(formGroup: UntypedFormGroup, original?: Pricing) {
    const formModel = formGroup.getRawValue();
    let pricing: Pricing = new Pricing(null);
    pricing.name = formModel.name;
    pricing.sku = formModel.sku;
    pricing.details = formModel.details;

    pricing.id = original ? original.id : null;

    return pricing;
  }
}
