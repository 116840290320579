import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { AlertService } from "../../commons/services/alert.service";

import { LaravelPricingService } from "src/app/commons/services/backend/laravel-pricing.service";
import * as ConfigActions from "../actions/config.actions";

@Injectable()
export class ConfigEffets {
  // error$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(ConfigActions.loadAvailableModeslFailed),
  //       tap(({ error }) => {
  //         if (error) {
  //           this.alertService.showErrorMessage("Error", error);
  //         }
  //       })
  //     );
  //   },
  //   { dispatch: false }
  // );

  loadModels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConfigActions.loadAvailableModesl),
      switchMap(() =>
        this.priceService
          .availableModels()
          .pipe(
            map((models) =>
              ConfigActions.loadAvailableModeslCompleted({ models })
            ),
            catchError((error) => {
              return of(ConfigActions.loadAvailableModeslFailed({ error }));
            })
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private priceService: LaravelPricingService,
  ) { }
}
