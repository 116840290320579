<identifai-user-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</identifai-user-filters>
<mat-card class="u-center-text" *ngIf="!(users | async).length">
  <h4>No user found</h4>
  <!-- <button *ngIf="canAdd" mat-raised-button (click)="addUser()">Add</button> -->
</mat-card>
<div [hidden]="!(users | async).length" class="table-container">
  <table mat-table matSort [dataSource]="users">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let user">{{ user.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let user">
        {{ user.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">
        {{ user.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let user">
        {{ user.role | userRole }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let user">
        <ng-container *ngIf="!canSelect">
          <button mat-icon-button color="primary" matTooltip="Credit" (click)="$event.stopPropagation(); balance(user)">
            <mat-icon>account_balance_wallet</mat-icon>
          </button>
          <button mat-icon-button color="primary" matTooltip="Classifications"
            (click)="$event.stopPropagation(); requests(user)">
            <mat-icon>format_list_bulleted</mat-icon>
          </button>
          <button mat-icon-button color="primary" matTooltip="Report management"
            (click)="$event.stopPropagation(); reports(user)">
            <mat-icon>report</mat-icon>
          </button>
          <button mat-icon-button color="primary" matTooltip="Logs" (click)="$event.stopPropagation(); logs(user)">
            <mat-icon>access_time_filled</mat-icon>
          </button>
        </ng-container>

        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectUser(user)">
          Select
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editUser(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>