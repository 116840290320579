import { createSelector } from '@ngrx/store';

import { selectPricingState } from '../reducers';
import { PricingState } from '../reducers/pricing.reducer';

// Pricing Selectors

export const getPricingsTableState = createSelector(
  selectPricingState,
  (state: PricingState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getPricings = createSelector(
  selectPricingState,
  (state: PricingState) => state.list
);

export const getTotalPricings = createSelector(
  getPricingsTableState,
  (tableState) => tableState.total
);

export const getPricingsCurrentPage = createSelector(
  getPricingsTableState,
  (tableState) => tableState.currentPage
);

export const getPricingsPerPage = createSelector(
  getPricingsTableState,
  (tableState) => tableState.perPage
);

export const getPricingsOrder = createSelector(
  getPricingsTableState,
  (tableState) => tableState.order
);

export const getPricingsDirection = createSelector(
  getPricingsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getPricingsTableState,
  (tableState) => tableState.filters
);

export const getPricingDialogId = createSelector(
  selectPricingState,
  (state: PricingState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectPricingState,
  (state: PricingState) => state.selectionDialogId
);
export const getCurrentFormControlName = createSelector(
  selectPricingState,
  (state: PricingState) => state.currentFormControlName
);
export const getSelectedPricing = createSelector(
  selectPricingState,
  (state: PricingState) => state.pricing
);
