import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap
} from "rxjs/operators";


import { LaravelPricingService } from "src/app/commons/services/backend/laravel-pricing.service";
import { AlertService } from "../../commons/services/alert.service";

import {
  getPricingDialogId,
  getPricingsTableState,
} from "src/app/store/selectors/pricing.selectors";
import * as PricingActions from "../actions/pricing.actions";
import * as PricingSelectors from "../selectors/pricing.selectors";
import { AppState } from "../reducers";
import { PricingSelectionComponent } from "src/app/modules/shared/components/pricing/pricing-selection/pricing-selection.component";
import { PricingEditComponent } from "src/app/modules/shared/components/pricing/pricing-edit/pricing-edit.component";

@Injectable()
export class PricingEffects {
  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PricingActions.savePricingFailed, PricingActions.loadPricingsFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage("Error", error);
          }
        })
      );
    },
    { dispatch: false }
  );

  loadPricings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.loadPricings),
      concatLatestFrom(() => this.store$.select(getPricingsTableState)),
      switchMap(([{ page, perPage, order, direction, filters, includes }, { filters: stateFilters }]) => {
        return this.pricingservice
          .list(page, perPage, order, direction, filters ? filters : stateFilters, includes)
          .pipe(
            map((result) =>
              PricingActions.loadPricingsCompleted({
                pricings: result.data,
                currentPage: page,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(PricingActions.loadPricingsFailed({ error }));
            })
          );
      })
    );
  });

  changePage = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.changePage),
      concatLatestFrom(() => this.store$.select(getPricingsTableState)),
      map(
        ([
          { page, size },
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          PricingActions.loadPricings({
            page: page,
            perPage: size,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  changeSort = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.changeSort),
      concatLatestFrom(() => this.store$.select(getPricingsTableState)),
      map(
        ([
          action,
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          PricingActions.loadPricings({
            page: currentPage,
            perPage: perPage,
            order: action.order,
            direction: action.direction,
            filters,
            includes,
          })
      )
    );
  });

  changeFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.changeFilters),
      concatLatestFrom(() => this.store$.select(getPricingsTableState)),
      map(
        ([
          { filters },
          { total, currentPage, perPage, direction, order, includes },
        ]) =>
          PricingActions.loadPricings({
            page: currentPage,
            perPage: perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  editPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.editPricing),
      map(({ pricing }) => {
        let dialogRef = this.dialog.open(PricingEditComponent, {
          data: {
            pricing
          },
        });
        return PricingActions.pricingDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  savePricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.savePricing),
      mergeMap(({ pricing }) =>
        this.pricingservice.upsert(pricing.toDTO()).pipe(
          map((result) => PricingActions.savePricingCompleted({ pricing: result })),
          catchError((error) => of(PricingActions.savePricingFailed({ error })))
        )
      )
    );
  });

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.savePricingCompleted),
      map((action) => action.pricing),
      tap((pricing) =>
        this.alertService.showConfirmMessage(
          `Pricing ${pricing.name} saved successfully`
        )
      ),
      map(() => PricingActions.closePricingDialog())
    );
  });

  deletePricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.deletePricing),
      switchMap(({ pricing }) =>
        this.alertService
          .showConfirmDialog(
            "Confirm deletion",
            `Are you sure you want to delete the transaction ${pricing.id}?`
          )
          .pipe(
            filter((confirm) => !!confirm),
            switchMap(() => this.pricingservice.delete(pricing.id).pipe(
              map(() => PricingActions.deletePricingCompleted({ pricing })),
              catchError((error) =>
                of(PricingActions.deletePricingFailed({ error }))
              )
            ))
          )
      )
    );
  });

  onDeleteCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.deletePricingCompleted),
      tap(({ pricing }) =>
        this.alertService.showConfirmMessage(
          `Transaction ${pricing.id} deleted successfully`
        )
      ),
      map(() => PricingActions.closePricingDialog())
    );
  });

  onDeletePricingFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.deletePricingFailed),
      tap((err) =>
        this.alertService.showErrorMessage(
          'Error',
          err
        )
      ),
      map(() => PricingActions.closePricingDialog())
    );
  })

  closeDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PricingActions.closePricingDialog),
        concatLatestFrom(() => this.store$.select(getPricingDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.savePricingCompleted),
      concatLatestFrom(() => this.store$.select(getPricingsTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          PricingActions.loadPricings({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  reloadAfterDelete = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.deletePricingCompleted),
      concatLatestFrom(() => this.store$.select(getPricingsTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          PricingActions.loadPricings({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  selectPricing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.selectPricing),
      map(({ filters }) => {
        let dialogRef = this.dialog.open(PricingSelectionComponent, {
          data: {
            defaultFilters: filters
          }
        });
        return PricingActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
      }))
  }
  );
  closeSelectionDialog = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.closeSelectionDialog),
      concatLatestFrom(() => this.store$.select(PricingSelectors.getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    )
  }, { dispatch: false }
  );

  pricingsSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PricingActions.pricingSelected),
      map(() => PricingActions.closeSelectionDialog())
    )
  })


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private pricingservice: LaravelPricingService,
    private dialog: MatDialog,
    private alertService: AlertService,
  ) { }
}
