<div>
  <mat-card class="u-center-text">
    <mat-card-header> </mat-card-header>

    <mat-card-content>
      <div class="mat-elevation-z8 mb-1">

        <request-list [requests]="requests" [defaultFilters]="defaultFilters" [total]="total"
          [displayedColumns]="displayedColumns" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
          (onFilterChange)="filtersChange($event)" (onSendRequest)="sendRequest()" (onReportRequest)="reportRequest()"
          (onShowRequest)="showRequest($event)" (onReloadRequest)="refreshRequest($event)"
          (onDeleteRequest)="deleteRequest($event)" (onReprocessRequest)="reprocessRequest($event)"></request-list>
      </div>

      <!-- <div class="btn-container">
            <button mat-raised-button (click)="sendRequest()" color="primary">
          Classify an image
        </button>
        <button mat-raised-button (click)="reportRequest()" color="primary">
          Report a classification
        </button>
      </div> -->
    </mat-card-content>
  </mat-card>
</div>