import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Pricing } from 'src/app/commons/models/pricing.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as PricingActions from 'src/app/store/actions/pricing.actions';
import { AppState } from 'src/app/store/reducers';
import * as PricingSelectors from 'src/app/store/selectors/pricing.selectors';

import { PricingsColumn } from '../pricing-list/pricing-list.component';
import { PricingFilters } from 'src/app/commons/models/pricing.model';

@Component({
  selector: 'identifai-pricing-selection',
  templateUrl: './pricing-selection.component.html',
  styleUrls: ['./pricing-selection.component.scss'],
})
export class PricingSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  pricings: Observable<Pricing[]>;
  filters: Observable<PricingFilters>;

  @Input()
  defaultFilters: PricingFilters = {
    search: '',
  };

  displayedColumns: PricingsColumn[] = [
    'name',
    'sku',
    'actions',
  ];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pricings = this.store$.pipe(
      select(PricingSelectors.getPricings),
      takeUntil(this.unsubscribe$),
      map((dtos) => (dtos ? dtos.map((dto) => new Pricing(dto)) : null))
    );
    this.total = this.store$.pipe(
      select(PricingSelectors.getTotalPricings),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(PricingSelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );
    if (data && data.defaultFilters) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(
      PricingActions.loadPricings({
        page: 1,
        perPage: this.pageSizeOptions[0],
        filters: this.defaultFilters,
      })
    );
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      PricingActions.changeSort({ order: sort.active, direction: sort.direction })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      PricingActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: PricingFilters) {
    this.store$.dispatch(PricingActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectPricing(pricing: Pricing) {
    if (pricing) {
      this.store$.dispatch(PricingActions.pricingSelected({ pricing: pricing.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(PricingActions.closeSelectionDialog());
  }
}
