import { GraphMultiSeries } from 'src/app/helpers/graph.utils';

import { Base, BaseDTO } from './base.model';
import { ClassificationOptions, Image, ImageDTO, VerdictDTO } from './image.model';
import { User, UserDTO } from './user.model';
import { Video, VideoClassificationResults, VideoDTO } from './video.model';

export type RequestType = "image" | "video";

export type ClassificationType = 'human' | 'artificial';

export interface ClassificationResult {
  classification: { label: ClassificationType, score: number }[];
  model: string;
  image_description?: string;
}

export interface RequestsGraphData {
  data: GraphMultiSeries[];
  total: number;
  totalArtificial: number;
  totalHuman: number;
  balance: number;
}

export interface RequestFilters {
  user?: User;
  search?: string;
  classification?: ClassificationType;
  version?: string;
  image?: File;
  type?: RequestType;
}

export interface RequestGraphFilters {
  user_id?: number;
  start?: Date;
  end?: Date;
  type?: RequestType;
}

export interface RequestDTO extends BaseDTO {
  user_id: number;
  image_hash: string;
  video_hash: string;
  force_classification: ClassificationType;

  type: RequestType;
  image: ImageDTO;
  video: VideoDTO;
  user: UserDTO;
  current_heuristic: string;
}

export class Request extends Base {
  userId: number;
  imageHash: string;
  videoHash: string;
  forceClassification: ClassificationType;
  type: RequestType;
  image: Image;
  video: Video;
  user: User;
  currentHeuristic: string;

  constructor(source: RequestDTO) {
    super(source);
    if (source) {
      this.userId = source.user_id;
      this.imageHash = source.image_hash;
      this.videoHash = source.video_hash;
      this.type = source.type;
      this.forceClassification = source.force_classification;
      if (source.image) {
        this.image = new Image(source.image);
      }
      if (source.video) {
        this.video = new Video(source.video);
      }
      this.user = new User(source.user);
      this.currentHeuristic = source.current_heuristic;
      // this.details = {
      //   "active_manifest": "urn:uuid:eb41d7d0-78f3-4de9-9f7f-6b8cbeb64d17",
      //   "manifests": {
      //     "urn:uuid:eb41d7d0-78f3-4de9-9f7f-6b8cbeb64d17": {
      //       "claim_generator": "Microsoft_Responsible_AI/1.0",
      //       "claim_generator_info": [
      //         {
      //           "name": "Microsoft Responsible AI Image Provenance",
      //           "version": "1.0"
      //         }
      //       ],
      //       "assertions": [
      //         {
      //           "label": "c2pa.actions",
      //           "data": {
      //             "actions": [
      //               {
      //                 "action": "c2pa.created",
      //                 "softwareAgent": "Bing Image Creator",
      //                 "when": "2024-01-04T05:59:13Z"
      //               }
      //             ]
      //           }
      //         }
      //       ],
      //       "signature_info": {
      //         "issuer": "Microsoft Corporation",
      //         "cert_serial_number": "1137338005225109437724101408746055755605475347",
      //         "time": "2024-01-04T05:59:12+00:00"
      //       },
      //     }
      //   }
      // }
    }
  }

  toDTO(): RequestDTO {
    let result: RequestDTO = <RequestDTO>super.toDTO();
    result.user_id = this.userId;
    result.image_hash = this.imageHash;
    result.video_hash = this.imageHash;
    result.type = this.type;
    result.current_heuristic = this.currentHeuristic;
    // result.force_classification = this.forceClassification;
    return result;
  }

  get name(): string {
    if (this.type === "image") {
      return this.image.name;
    } else {
      return this.video.name;
    }
  }

  get path(): string {
    if (this.type === "image") {
      return this.image.path;
    } else {
      return this.video.path;
    }
  }

  get classification(): ClassificationType {
    if (!!this.forceClassification) {
      return this.forceClassification;
    }
    if (this.type === "image") {
      return this.image.getClassification(this.currentHeuristic);
    }
    return this.video.getClassification(this.currentHeuristic);
  }

  get confidence(): number {
    if (!!this.forceClassification) {
      return 1;
    }
    if (this.type === "image") {
      return this.image.getConfidence(this.currentHeuristic);
    }
    return this.video.getConfidence(this.currentHeuristic);
  }

  get hash(): string {
    return this.imageHash || this.videoHash;
  }

  get results(): ClassificationResult[] | VideoClassificationResults {
    return this.type === "image" ? this.image.results : this.video.results;
  }

  get response(): any {
    return this.type === "image" ? this.image.response : this.video.results;
  }

  get verdicts(): VerdictDTO[] {
    return this.type === "image" ? this.image.verdicts : this.video.verdicts;
  }

  get falsePositive(): boolean {
    return !!this.forceClassification || !!this.image?.falsePositive;
  }

  get userName(): string {
    return this.user.name;
  }

  get createdAtDate(): string {
    let createAtTime = new Date(this.createdAt);
    createAtTime.setHours(0, 0, 0, 0);
    return createAtTime.toLocaleDateString();
  }

  get classificationOptions(): ClassificationOptions {
    return this.type === "image" ? this.image.classificationOptions : this.video.classificationOptions;
  }

  get description(): string {
    return this.type === "image" ? this.image.description : this.video.description;
  }

}
