<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="pricing">Edit pricing: {{ pricing.name }}</span>
    <span *ngIf="!pricing">New pricing</span>
  </span>
  <span *ngIf="pricing">
    <ng-container>
      <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
        <button mat-menu-item (click)="deletePricing()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </mat-menu>
    </ng-container>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="pricingForm" novalidate>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Name" formControlName="name" type="text" required />
      <mat-error>Field required</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Sku" formControlName="sku" type="text" required />
      <mat-error>Field required</mat-error>
    </mat-form-field>
    <ng-container formGroupName="details">
      <div class="u-flex-full">
        <mat-form-field class="u-full-width">
          <mat-label>Price</mat-label>
          <input matInput type="number" formControlName="price">
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <mat-label>Credits</mat-label>
          <input matInput type="number" formControlName="credits">
        </mat-form-field>
      </div>
      <div class="u-flex-full">
        <mat-form-field class="u-full-width">
          <mat-label>Discount</mat-label>
          <input matInput type="number" formControlName="discount" min="0" max="100">
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <mat-label>Period</mat-label>
          <mat-select formControlName="period">
            <mat-option [value]="null">-- No value --</mat-option>
            <mat-option *ngFor="let period of periods" [value]="period">{{period}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container formGroupName="request_options">
        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>Image Options</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="u-flex-full">
              <mat-form-field class="u-full-width">
                <mat-label>Enabled models</mat-label>
                <mat-select formControlName="models" multiple>
                  <mat-option *ngFor="let model of models" [value]="model">{{model}}</mat-option>
                </mat-select>
                <mat-hint>Leave empty to allow all models</mat-hint>
              </mat-form-field>
            </div>
            <div class="u-flex-full">
              <mat-checkbox formControlName="morphing">Include morphing detection model</mat-checkbox>
              <mat-checkbox formControlName="llm">Include LLM model</mat-checkbox>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>Video Options</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <mat-checkbox formControlName="video">Enable video submission</mat-checkbox>
            <div *ngIf="!!pricingForm?.value?.details?.request_options?.video" class="u-full-width">
              <mat-checkbox formControlName="video_keyframes">Keyframes extraction</mat-checkbox>
              <mat-checkbox formControlName="video_credit_choice">Choose number of frames (credits)</mat-checkbox>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-container formGroupName="response_options">
        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>Response Options</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <mat-checkbox formControlName="model_details">Model details</mat-checkbox>
            <mat-checkbox formControlName="heuristics">Heuristics</mat-checkbox>
            <mat-checkbox formControlName="telemetry">Telemetry</mat-checkbox>
            <mat-checkbox formControlName="bias_detection">Bias detection</mat-checkbox>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </ng-container>
  </form>
  <!-- <pre>{{pricingForm.value | json}}</pre> -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()" [disabled]="pricingForm?.pristine">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="pricingForm?.pristine || !pricingForm?.valid">
      Save
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
