export function toFormData<T>(formValue: T, method?: "GET" | "PUT" | "POST") {
  const formData = new FormData();

  for (const key of Object.keys(formValue)) {
    let value = formValue[key];
    if (value != null) {
      if (typeof value === 'boolean') {
        value = value ? 1 : 0;
      }
      formData.append(key, value);
    }
  }

  if (method) {
    formData.append('_method', method);
  } else {
    formData.append('_method', 'POST');
  }

  return formData;
}
