<header mat-dialog-title class="u-flex-full">
  <span>
    {{request.name}}
  </span>
  <span *ngIf="request.classification" style="text-align: right;">
    {{ request.confidence | percent }} {{ request?.classification | classification }}
    <span class="badge badge--primary" *ngIf="request?.falsePositive">Reported</span>
    <span class="badge badge--primary badge--small" matTooltip="Image has a C2PA Manifest"
      *ngIf="!!request?.image?.details">C2PA Manifest</span>
    <div *ngIf="request.type == 'video'" style="font-size: 14px;
    line-height: normal;
    opacity: .5;">
      {{request?.classification | classification}} samples: {{request.video.getClassifiedFrames(currentUser.heuristic)}}
      of
      {{request.video.frames}}
    </div>
  </span>
  <span *ngIf="!request.classification">
    Analyzing...
  </span>
</header>

<mat-dialog-content>
  <div class="info">
    <div class="col-1">
      <div class="w-full flex" *ngIf="request?.path">

        <img *ngIf="request.type === 'image'" src="{{ request?.path }}" height="300px" /><br />
        <video *ngIf="request.type === 'video'" src="{{ request?.path }}" height="300px" controls></video><br />
      </div>
    </div>

    <div class="col-2">
      <mat-tab-group>
        <mat-tab label="Main data">
          <ng-container *ngIf="request.image?.CPAManifest as c2pa">
            <div class="w-full" *ngIf="c2pa.title">
              <b>Title:</b>
              {{c2pa.title}}
            </div>
            <div class="w-full" *ngIf="c2pa.format">
              <b>Format:</b>
              {{c2pa.format}}
            </div>
            <div class="w-full" *ngIf="c2pa.claim_generator">
              <b>Claim generator:</b>
              {{c2pa.claim_generator}}
            </div>
            <div class="w-full" *ngIf="c2pa.claim_generator_info?.length && c2pa.claim_generator_info[0].name">
              <b>Issuer:</b>
              {{c2pa.claim_generator_info[0].name}}
            </div>
            <div class="w-full" *ngIf="c2pa.ingredients">
              <b>Ingredients:</b>
              {{c2pa.ingredients.length}}
            </div>
          </ng-container>
          <div class="w-full" *showFor="['admin']">
            <b>Author:</b>
            {{request.user?.name}}
          </div>
          <div class="w-full">
            <b>Type:</b>
            {{ request.type | file_type }}
          </div>
          <div class="w-full" *ngIf="request.video?.frames">
            <b>Frames:</b>
            {{request.video?.frames}}
          </div>
          <div class="w-full">
            <b>Hash:</b>
            {{ request.hash }}
          </div>
          <div class="w-full">
            <b>Date & Time:</b>
            {{ request.createdAt | date : "short" }}
          </div>

          <ng-container *ngIf="request.image as image">
            <div class="w-full">
              <b>Reported:</b>
              {{ image?.falsePositive || request.falsePositive ? "Yes" : "No" }}
            </div>
          </ng-container>
          <div class="w-full" *ngIf="request.classification">
            <b>Verdict:</b>
            {{ request.confidence | percent }} {{ request.classification | classification }}
            <span *ngIf="request.type == 'video'">
              - {{request.video.getClassifiedFrames(currentUser.heuristic)}} of {{request.video.frames}} samples
              classified as
              {{request?.classification}}
            </span>
          </div>
        </mat-tab>
        <mat-tab label="Classification details">
          <ng-container *ngIf="request.image?.CPAManifest as c2pa">
            <!--TODO Actions timeline-->
          </ng-container>
          <!-- <ngx-charts-bar-vertical [results]="(requestGraphData | async)?.data"
          [legend]="currentUser.admin && !defaultFilters?.user" [showXAxisLabel]="requestGraphConfig.showXAxisLabel"
          [showYAxisLabel]="requestGraphConfig.showYAxisLabel" [xAxis]="requestGraphConfig.xAxis"
          [yAxis]="requestGraphConfig.yAxis" [xAxisLabel]="requestGraphConfig.xAxisLabel"
          [yAxisLabel]="requestGraphConfig.yAxisLabel" [scheme]="requestGraphConfig.scheme">
        </ngx-charts-bar-vertical> -->
          <identifai-image-models *ngIf="request.image?.results" [results]="request.image.results"
            [verdict]="request.classification"></identifai-image-models>
          <identifai-video-models *ngIf="request.video?.results" [results]="request.video.results"
            [verdict]="request.classification" [excludedModels]="request.video.biasedModels"></identifai-video-models>
        </mat-tab>
        <mat-tab label="History" *ngIf="request.image?.CPAActions.length">
          <mat-stepper orientation="vertical">
            <mat-step class="action" *ngFor="let action of request.image.CPAActions" [editable]="true">
              <ng-template matStepLabel>{{action.action | c2pa_action}}</ng-template>
              <ng-container *ngIf="action.softwareAgent || action.when else no_details">
                <div class="action__type" *ngIf="action.softwareAgent"><b>Software agent: </b>{{action.softwareAgent}}
                </div>
                <div class="action__type" *ngIf="action.when"><b>When:</b> {{action.when}}</div>
              </ng-container>
              <ng-template #no_details>
                <div class="action__description">No details available</div>
              </ng-template>
            </mat-step>
            <ng-template matStepperIcon="number">
              <mat-icon>assignment_turned_in</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="edit">
              <mat-icon>assignment_turned_in</mat-icon>
            </ng-template>
          </mat-stepper>
          <!-- <div class="action" *ngFor="let action of request.image.CPAActions">
            <ng-container *ngIf="action">
              <div class="action__type" *ngIf="action.softwareAgent">Created by {{action.softwareAgent}} at
                {{action.when}}</div>
              <div class="action__description" *ngIf="action.action">{{action.action | c2pa_action}} </div>
            </ng-container>
          </div> -->
        </mat-tab>
        <mat-tab label="Heuristic comparison">
          <identifai-verdicts [request]="request" [currentHeuristic]="currentUser.heuristic"
            (onChangeHeuristic)="updateHeuristic($event)"></identifai-verdicts>
        </mat-tab>
        <ng-container *showFor="['admin']">
          <mat-tab label="Classifier response" *ngIf="request.classification">
            <div>
              <ngx-json-viewer [json]="request.response" [depth]="1"></ngx-json-viewer>
            </div>
            <button mat-button matTooltip="Download Response" color="primary" (click)="downloadResponse()">
              Download
            </button>
          </mat-tab>
        </ng-container>
        <mat-tab label="C2PA Manifest" *ngIf="request.image?.details as details">
          <div>
            <ngx-json-viewer [json]="details" [depth]="1"></ngx-json-viewer>
          </div>
          <button mat-button matTooltip="Download C2PA Manifest" color="primary" (click)="downloadC2PA()">
            Download
          </button>
        </mat-tab>
      </mat-tab-group>
      <mat-accordion [multi]="true" *ngIf="false">
        <mat-expansion-panel *ngIf="request.image?.CPAManifest as manifest">
          <mat-expansion-panel-header>C2PA Details</mat-expansion-panel-header>
          <div class="w-full" *ngIf="request.image?.CPAActions.length">
            <b>Document history</b>
            <div class="action" *ngFor="let action of request.image?.CPAActions">
              <ng-container *ngIf="action">
                <pre>{{action | json}}</pre>
                <div class="action__type" *ngIf="action.softwareAgent">Created by {{action.softwareAgent}} at
                  {{action.when}}</div>
                <div class="action__description" *ngIf="action.action">{{action.action | c2pa_action}} </div>
              </ng-container>
            </div>
            <!-- <div class="action">
                <div class="action__type">Created by Bing Image Creator at 2024-01-04 05:59:13</div>
                <div class="action__description">
                  The asset was first created, usually the asset’s origin.</div>
              </div>
              <div class="action">
                <div class="action__type">Edited by Adobe Photoshop at 2024-01-05 15:50:23</div>
                <div class="action__description">
                  Generalized actions that would be considered 'editorial transformations' of the content.</div>
              </div> -->
          </div>
          <div class="w-full" *ngIf="manifest.claim_generator_info[0].name">
            <b>Issuer:</b>
            {{manifest.claim_generator_info[0].name}}
          </div>
          <div class="w-full" *ngIf="manifest.signature_info.cert_serial_number">
            <b>Signature:</b>
            {{manifest.signature_info.cert_serial_number}} <span *ngIf="manifest.signature_info.issuer">by
              {{manifest.signature_info.issuer}}</span>
          </div>

        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>Classification Details</mat-expansion-panel-header>
          <div class="w-full">
            <b>Hash:</b>
            {{ request.imageHash }}
          </div>


          <div class="w-full">
            <b>Date & Time</b>
            {{ request.createdAt | date : "short" }}
          </div>

          <ng-container *ngIf="request.image as image">
            <div class="w-full">
              <b>Reported:</b>
              {{ image?.falsePositive || request.falsePositive ? "Yes" : "No" }}
            </div>

            <div class="w-full" *ngIf="image.results">
              <b>{{image.results.length}} models used:</b>
              <div class="model" *ngFor="let result of image.results ">
                <span class="model__name">{{result.model}}</span>
                <span>
                  <div class="model__classification" *ngFor="let classification of result.classification">
                    {{classification.score| percent}} {{classification.label | classification}}
                  </div>
                </span>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="primary" type="button" (click)="report()">
    Report
  </button>

  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>