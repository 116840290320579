import { Action, createReducer, on } from '@ngrx/store';
import { mutableOn } from 'ngrx-etc';
import { RequestDTO, RequestFilters, RequestGraphFilters, RequestsGraphData } from 'src/app/commons/models/request.model';
import { GraphMultiSeries } from 'src/app/helpers/graph.utils';
import { substractDays } from 'src/app/helpers/time.utils';

import * as RequestActions from '../actions/request.actions';

export interface RequestState {
  list: RequestDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RequestFilters,
  dialogId: string,
  graphData: RequestsGraphData,
  graphUserAndVerdict: GraphMultiSeries[]
  graphUserAndType: GraphMultiSeries[]
  graphDateAndVerdict: GraphMultiSeries[]
  graphImagesDateAndVerdict: GraphMultiSeries[]
  graphVideosDateAndVerdict: GraphMultiSeries[]
  graphFilters: RequestGraphFilters
};

const initialState: RequestState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  graphData: null,
  graphUserAndVerdict: null,
  graphUserAndType: null,
  graphDateAndVerdict: null,
  graphImagesDateAndVerdict: null,
  graphVideosDateAndVerdict: null,
  graphFilters: {
    start: substractDays(new Date(), 30),
    end: new Date()
  }
};

const userReducer = createReducer(
  initialState,
  on(RequestActions.loadRequestsCompleted, (state, { requests, currentPage, total, perPage, order, direction, includes }): RequestState => {
    return { ...state, list: requests, currentPage, total, perPage, order, direction, includes };
  }),
  on(RequestActions.changeFilters, (state, { filters }): RequestState => {
    return { ...state, currentPage: 1, filters };
  }),
  on(RequestActions.requestDialogOpened, (state, { dialogId }): RequestState => {
    return { ...state, dialogId };
  }),
  on(RequestActions.showRequests, (state, { user }): RequestState => {
    return { ...state, filters: user ? { ...state.filters, user: user } : state.filters };
  }),
  on(RequestActions.loadRequestsGraphCompleted, (state, { result: graphData }): RequestState => {
    return { ...state, graphData };
  }),
  on(RequestActions.loadGraphUserAndTypeCompleted, (state, { result }): RequestState => {
    return { ...state, graphUserAndType: result };
  }),
  on(RequestActions.loadGraphUserAndVerdictCompleted, (state, { result }): RequestState => {
    return { ...state, graphUserAndVerdict: result };
  }),
  on(RequestActions.loadGraphDateAndVerdictCompleted, (state, { result }): RequestState => {
    return { ...state, graphDateAndVerdict: result };
  }),
  on(RequestActions.loadGraphImagesDateAndVerdictCompleted, (state, { result }): RequestState => {
    return { ...state, graphImagesDateAndVerdict: result };
  }),
  on(RequestActions.loadGraphVideosDateAndVerdictCompleted, (state, { result }): RequestState => {
    return { ...state, graphVideosDateAndVerdict: result };
  }),
  on(RequestActions.changeGraphFilters, (state, { filters }): RequestState => {
    return { ...state, graphFilters: filters };
  }),
  mutableOn(RequestActions.refreshRequestCompleted, (state, { request }) => {
    let existing = state.list.find(r => r.id == request.id)
    existing.image = request.image;
    existing.video = request.video;
  })
);

export function reducer(state: RequestState | undefined, action: Action) {
  return userReducer(state, action);
}

