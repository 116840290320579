import { Action, createReducer, on } from '@ngrx/store';

import * as PricingActions from '../actions/pricing.actions';
import { PricingFilters, Pricing, PricingDTO, } from 'src/app/commons/models/pricing.model';

export interface PricingState {
  list: PricingDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: PricingFilters,
  dialogId: string,
  pricing: Pricing,
  selectionDialogId: string,
  currentFormControlName: string,
};

const initialState: PricingState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  pricing: null,
  selectionDialogId: null,
  currentFormControlName: null,
};

const pricingReducer = createReducer(
  initialState,
  on(PricingActions.loadPricingsCompleted, (state, { pricings, currentPage, total, perPage, order, direction, includes }): PricingState => {
    return { ...state, list: pricings, currentPage, total, perPage, order, direction, includes };
  }),
  on(PricingActions.pricingDialogOpened, (state, { dialogId }): PricingState => {
    return { ...state, dialogId };
  }),
  on(PricingActions.changeFilters, (state, { filters }): PricingState => {
    return { ...state, currentPage: 1, filters };
  }),
  on(PricingActions.selectionDialogOpened, (state, { selectionDialogId }): PricingState => {
    return { ...state, selectionDialogId };
  }),
  on(PricingActions.selectPricing, (state, { filters, currentFormControlName }): PricingState => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),
);

export function reducer(state: PricingState | undefined, action: Action) {
  return pricingReducer(state, action);
}

