import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteFormat'
})
export class ByteFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return `${value / (1024 * 1024)} mB`;
    }
    return "";
  }

}
